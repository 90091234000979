html, body {
    background: linear-gradient(180deg, #ffd79a 9.9%, #ff9f9f 100%);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*make countries flags circles*/
.PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    margin-left: 4px;
    box-shadow: none;
}

.PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.PhoneInputCountrySelectArrow {
    margin-left: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
